import React from "react";
import { Layout } from "../../components/Layout";
import { Head } from "../../components/Head";
import { CaseStudiesTitle } from "../../components/CaseStudiesTitle";
import { CaseStudiesText } from "../../components/CaseStudiesText";

export default function CS_Dermatology() {
  const title = "Dermatology / plastic surgery";
  const subTitle = "皮膚科・形成外科";
  const overview = [
    "高価な機器を保持しない開業の場合、他の診療科目と比較して開業資金が低いです。",
    <br />,
    "また、２つの診察室と１つの処置室を設ける場合が多いため、25〜30坪で開業される先生が多いです",
  ];
  const openingCash = "おおよその開業資金";
  const openingCashText = [
    "土地、建物　7,000万円～15,000万円です。",
    <br />,
    "設備　500万円〜（ただし各種レーザー機器を導入しない場合）",
    <br />,
    "電子カルテ、レジスター、コピー複合機、診察用ベッド、顕微鏡、無影灯、オートクレーブ など",
  ];
  const point = "ポイント";
  const pointText = [
    "・スモールスタートで開業",
    <br />,
    "・人通りが多い場所に開業する",
    <br />,
    "・患者様のターゲット層を絞る",
    <br />,
    <br />,
    "高価な装置を設置しなくても開業が可能のため開業後にクリニックのコンセプトにあった装置の購入が可能です",
    <br />,
    "先生が幼児を診察する場合、付き添いの保護者に化粧品を勧める方法等を行い、クリニックの集患を行うことが重要です",
  ];

  return (
    <Layout>
      <Head props={subTitle} />
      <CaseStudiesTitle title={[title, subTitle]} />
      <CaseStudiesText
        textData={[overview, openingCash, openingCashText, point, pointText]}
      />
    </Layout>
  );
}
